<template>
  <v-hover v-slot="{ hover }">
    <div class="floating-btn-container" :class="{ hovered: hover }">
      <div
        :class="{ 'floating-btn': true, disabled: disabled, hovered: hover }"
        @click="($event) => $emit('click', $event)"
      >
        <v-fade-transition hide-on-leave>
          <v-icon key="add" v-if="hover" class="add" color="white">
            mdi-plus
          </v-icon>
          <v-icon key="arrow" v-else class="arrow" color="white">
            mdi-chevron-left
          </v-icon>
        </v-fade-transition>
      </div>
    </div>
  </v-hover>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.scroll-visible {
  .floating-btn-container {
    position: fixed;
    bottom: 24px;
    right: 17px;
  }
}
.floating-btn-container {
  transition: width ease-in 0.25s;
  width: 30px;
  &.hovered {
    width: 55px;
    .floating-btn {
      width: 48px;
      border-top-right-radius: 24px;
      border-bottom-right-radius: 24px;
      right: 21px;
    }
  }
  position: fixed;
  bottom: 24px;
  right: 0px;
}
.floating-btn {
  transition: ease-in 0.25s;
  cursor: pointer;
  background: #3f51b5;
  color: white;

  width: 32px;
  height: 48px;
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.8;
    cursor: initial;
  }
}
</style>
